@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: calc(100vh + 120px);
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -1000;
  overflow: hidden;
}

.container {
  width: 100%;
  grid-template-rows: auto auto;
  position: absolute;
  background: vars.$white;
  z-index: 3000;
  display: none !important;
  left: 100%;
  & a {
    transition: color 0.5s ease;
  }
  & a:hover {
    color: vars.$blue;
  }
  @media screen and (max-width: vars.$medium) {
    display: grid !important;
    min-height: calc(var(--app-height) - 85px / vars.$medium * 100vw);
    max-height: calc(var(--app-height) - 85px / vars.$medium * 100vw);
    top: calc(85px / vars.$medium * 100vw);
    padding: 0 calc(20px / vars.$medium * 100vw);
    padding-top: calc(60px / vars.$medium * 100vw);
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 200px;
      background: inherit;
    }
  }
  @media screen and (max-width: vars.$small) {
    overflow: auto;
    position: fixed;
    top: calc(56px / vars.$small * vars.$smallCoef * 100vw);
    bottom: 0;
    padding: 0 calc(20px / vars.$small * vars.$smallCoef * 100vw);
    max-height: none;
    padding-top: calc(120px / vars.$small * vars.$smallCoef * 100vw);
    &::after {
      display: none;
    }
  }
}

.tint {
  display: none;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($color: vars.$black, $alpha: 0.8);
  z-index: 10;
  @media screen and (max-width: vars.$medium) {
    top: calc(100px / vars.$medium * 100vw);
  }
}
.tint_show {
  @media screen and (max-width: vars.$medium) {
    display: block;
  }
  @media screen and (max-width: vars.$small) {
    display: none;
  }
}

.menu {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: vars.$medium) {
    & > a {
      font-size: calc(28px / vars.$medium * 100vw);
      line-height: calc(40px / vars.$medium * 100vw);
      &:hover {
        color: vars.$blue;
      }
    }
    gap: calc(32px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    & > a {
      font-size: calc(28px / vars.$small * vars.$smallCoef * 100vw);
      line-height: calc(40px / vars.$small * vars.$smallCoef * 100vw);
      &:hover {
        color: vars.$blue;
      }
    }
    gap: calc(32px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.phone {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  align-self: end;
  color: vars.$blue;
  margin-bottom: 97px;
  @extend .H3;
  @media screen and (max-width: vars.$medium) {
    padding-top: calc(27px / vars.$medium * 100vw);
    margin-bottom: calc(20px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    padding-top: calc(27px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: calc(152px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.btn {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
  width: 100%;
  align-self: end;
  @media screen and (max-width: vars.$medium) {
    display: none !important;
  }
  @media screen and (max-width: vars.$small) {
    display: flex !important;
    font-size: calc(18px / vars.$small * vars.$smallCoef * 100vw);
    line-height: calc(26px / vars.$small * vars.$smallCoef * 100vw);
    padding: calc(23px / vars.$small * vars.$smallCoef * 100vw)
      calc(50px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: calc(60px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.link {
  cursor: pointer;
  &:hover {
    color: vars.$blue !important;
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(28px / vars.$medium * 100vw);
    line-height: calc(40px / vars.$medium * 100vw);
    gap: calc(20px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(28px / vars.$small * vars.$smallCoef * 100vw);
    line-height: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    gap: calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.sublink {
  cursor: pointer;
  &:hover {
    color: vars.$blue !important;
  }
}
