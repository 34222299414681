@use "../../utils/variables" as vars;

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 40px;
  justify-items: start;
  @media screen and (max-width: vars.$big) {
    column-gap: calc(40px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media screen and (max-width: vars.$small) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: calc(20px / vars.$big * 100vw);
  }
}

.couple {
  & > div:first-child {
    grid-column: 1 / 4;
  }

  & > div:nth-child(2) {
    grid-column: 4 / 13;
  }

  @media screen and (max-width: vars.$medium) {
    & > div:first-child {
      grid-column: 1 / 2;
    }

    & > div:nth-child(2) {
      grid-column: 2 / 5;
    }
  }

  @media screen and (max-width: vars.$small) {
    & > div:first-child {
      grid-column: 1 / 2;
    }

    & > div:nth-child(2) {
      grid-column: 2 / 3;
    }
  }
}
