@use "../../../utils/variables" as vars;

.container {
}

.title {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    color: vars.$blue;
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(28px / vars.$medium * 100vw);
    line-height: calc(40px / vars.$medium * 100vw);
    gap: calc(24px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(28px / vars.$small * vars.$smallCoef * 100vw);
    line-height: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    gap: calc(24px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.blue {
  color: vars.$blue;
}

.arrow {
  @media screen and (max-width: vars.$medium) {
    gap: calc(24px / vars.$medium * 100vw);
    width: calc(16px / vars.$medium * 100vw);
    height: calc(10px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    width: calc(16px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(10px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.list {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 0;
  @media screen and (max-width: vars.$medium) {
    & > div:first-child {
      padding-top: calc(20px / vars.$medium * 100vw);
    }
    font-size: calc(20px / vars.$medium * 100vw);
    line-height: calc(20px / vars.$medium * 100vw);
    padding-left: calc(20px / vars.$medium * 100vw);
    gap: calc(20px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    & > div:first-child {
      padding-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    }
    font-size: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    line-height: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    padding-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    gap: calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}
