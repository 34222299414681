// COLORS
$black: #263b47;
$blue: #036ab5;
$blue2: #d8e3ec;
$white: #ffffff;
$error_color: #ff3a2c;
$show_burger: 1211px;
$hide_suggestion: 810px;

// BREAKPOINTS
$large: 1920px;
$big: 1920px;
$medium: 1024px;
$small: 600px;

// coefficient is needed since actual layouts
// are bigger than figma layouts
$smallCoef: calc($small / 390px);

// SPACING
// BIG SCREEN
// $SCREEN_SIZE-SPACE_SIZE
$large-XL: 160px;
$large-XL-vw: 8.3vw;
$large-L: 120px;
$large-S: 80px;
$medium-XL: 120px;
$medium-XL-vw: 6.3vw;
$medium-L: 80px;
$medium-S: 40px;
$small-XL: 80px;
$small-XL-vw: 4.2vw;
$small-L: 60px;
$small-S: 40px;

// SIZES
$nav-height: calc(67px + 1.6vw * 2);
$nav-height-medium: calc(40px + 1.6vw * 2);
$nav-height-small: 70px;

// OTHERS
$btn_hover_zone: 40px;
