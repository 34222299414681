@use "../../utils/variables" as vars;

$error_color: #ff3a2c;

.group {
  position: relative;
  margin-bottom: 20px;
  @media screen and (max-width: vars.$big) {
    margin-bottom: calc(20px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    margin-bottom: calc(20px / vars.$medium * 100vw);
  }
}

.textfield {
  font-family: Suisse Intl;
  font-size: 14px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid vars.$white;
  background: none;
  caret-color: vars.$white;
  color: vars.$white;
  resize: none;
  @media screen and (max-width: vars.$big) {
    font-size: calc(14px / vars.$big * 100vw);
    padding: calc(10px / vars.$big * 100vw) calc(10px / vars.$big * 100vw)
      calc(10px / vars.$big * 100vw) calc(5px / vars.$big * 100vw);
    border-bottom: calc(1px / vars.$big * 100vw) solid vars.$white;
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(14px / vars.$medium * 100vw);
    padding: calc(10px / vars.$medium * 100vw) calc(10px / vars.$medium * 100vw)
      calc(10px / vars.$medium * 100vw) calc(5px / vars.$medium * 100vw);
    border-bottom: calc(1px / vars.$medium * 100vw) solid vars.$white;
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(14px / vars.$small * vars.$smallCoef * 100vw);
    padding: calc(10px / vars.$small * vars.$smallCoef * 100vw)
      calc(10px / vars.$small * vars.$smallCoef * 100vw)
      calc(10px / vars.$small * vars.$smallCoef * 100vw)
      calc(5px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: calc(25px / vars.$small * vars.$smallCoef * 100vw);
    border-bottom: calc(1px / vars.$small * vars.$smallCoef * 100vw) solid
      vars.$white;
  }
}
.textfield:focus {
  outline: none;
}

.error {
  border-bottom: 1px solid vars.$error_color;
  color: $error_color;
  @media screen and (max-width: vars.$big) {
    border-bottom: calc(1px / vars.$big * 100vw) solid vars.$error_color;
  }
  @media screen and (max-width: vars.$medium) {
    border-bottom: calc(1px / vars.$medium * 100vw) solid vars.$error_color;
  }
  @media screen and (max-width: vars.$small) {
    border-bottom: calc(1px / vars.$small * vars.$smallCoef * 100vw) solid
      vars.$error_color;
  }
}

.error ~ .label {
  color: $error_color !important;
}

/* LABEL ======================================= */
.label {
  color: vars.$white;
  font-size: 14px;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  @media screen and (max-width: vars.$big) {
    font-size: calc(14px / vars.$big * 100vw);
    left: calc(5px / vars.$big * 100vw);
    top: calc(10px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(14px / vars.$medium * 100vw);
    left: calc(5px / vars.$medium * 100vw);
    top: calc(10px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(14px / vars.$small * vars.$smallCoef * 100vw);
    left: calc(5px / vars.$small * vars.$smallCoef * 100vw);
    top: calc(10px / vars.$small * vars.$smallCoef * 100vw);
  }
}

/* active state */
.textfield:focus ~ .label,
.textfield:valid ~ .label {
  top: -20px;
  font-size: 12px;
  color: vars.$white;
  @media screen and (max-width: vars.$big) {
    top: calc(-20px / vars.$big * 100vw);
    font-size: calc(12px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    top: calc(-20px / vars.$medium * 100vw);
    font-size: calc(12px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    top: calc(-20px / vars.$small * vars.$smallCoef * 100vw);
    font-size: 12px;
  }
}

.textfield__red:focus ~ .label,
.textfield__red:valid ~ .label {
  color: $error_color;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: vars.$white;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  @media screen and (max-width: vars.$big) {
    height: calc(2px / vars.$big * 100vw);
    bottom: calc(1px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    height: calc(2px / vars.$medium * 100vw);
    bottom: calc(1px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    height: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    bottom: calc(1px / vars.$small * vars.$smallCoef * 100vw);
  }
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}
