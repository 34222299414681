@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  display: flex;
  color: vars.$white;
  @extend .T2;
  & a {
    transition: color 0.5s ease;
    &:hover {
      color: vars.$blue;
    }
  }
}

.blue {
  & a {
    &:hover {
      color: vars.$white;
      text-decoration: underline;
    }
  }
}

.error {
  color: vars.$error_color;
  & .checkmark {
    border-color: vars.$error_color;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 8px;
  z-index: 100;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: vars.$big) {
    padding-left: calc(25px / vars.$big * 100vw);
    margin-top: calc(8px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    padding-left: calc(25px / vars.$medium * 100vw);
    margin-top: calc(8px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    padding-left: calc(25px / vars.$small * vars.$smallCoef * 100vw);
    margin-top: calc(8px / vars.$small * vars.$smallCoef * 100vw);
  }
}

/* Hide the browser's default checkbox */
.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 12px;
  width: 12px;
  border: 1px solid vars.$white;
  transition: all 0.5s ease;
  @media screen and (max-width: vars.$big) {
    height: calc(12px / vars.$big * 100vw);
    width: calc(12px / vars.$big * 100vw);
    border: calc(1px / vars.$big * 100vw) solid vars.$white;
  }
  @media screen and (max-width: vars.$medium) {
    height: calc(12px / vars.$medium * 100vw);
    width: calc(12px / vars.$medium * 100vw);
    border: calc(1px / vars.$medium * 100vw) solid vars.$white;
  }
  @media screen and (max-width: vars.$small) {
    height: calc(12px / vars.$small * vars.$smallCoef * 100vw);
    width: calc(12px / vars.$small * vars.$smallCoef * 100vw);
    border: calc(1px / vars.$small * vars.$smallCoef * 100vw) solid vars.$white;
  }
}

/* When the checkbox is checked, add background */
.checkbox input:checked ~ .checkmark {
  background-color: vars.$white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 3px;
  top: 2px;
  width: 2px;
  height: 5px;
  border: solid vars.$blue;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  @media screen and (max-width: vars.$big) {
    left: calc(3px / vars.$big * 100vw);
    top: calc(2px / vars.$big * 100vw);
    width: calc(2px / vars.$big * 100vw);
    height: calc(5px / vars.$big * 100vw);
    border-width: calc(0px / vars.$big * 100vw) calc(2px / vars.$big * 100vw)
      calc(2px / vars.$big * 100vw) calc(0px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    left: calc(3px / vars.$medium * 100vw);
    top: calc(2px / vars.$medium * 100vw);
    width: calc(2px / vars.$medium * 100vw);
    height: calc(5px / vars.$medium * 100vw);
    border-width: calc(0px / vars.$medium * 100vw)
      calc(2px / vars.$medium * 100vw) calc(2px / vars.$medium * 100vw)
      calc(0px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    left: calc(3px / vars.$small * vars.$smallCoef * 100vw);
    top: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    width: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(5px / vars.$small * vars.$smallCoef * 100vw);
    border-width: calc(0px / vars.$small * vars.$smallCoef * 100vw)
      calc(2px / vars.$small * vars.$smallCoef * 100vw)
      calc(2px / vars.$small * vars.$smallCoef * 100vw)
      calc(0px / vars.$small * vars.$smallCoef * 100vw);
  }
}
