@use "../../../utils/variables" as vars;

.container {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 2000;
  & > li:first-child {
    margin-right: 20px;
  }
  & li {
    position: relative;
    display: block;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  & li.selected::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    bottom: 0;
    background-color: currentColor;
  }
  &__dark li::after {
    background-color: vars.$black;
  }
  &__light li::after {
    background-color: vars.$white;
  }

  @media screen and (max-width: vars.$big) {
    & > li:first-child {
      margin-right: calc(20px / vars.$big * 100vw);
    }
    & li.selected::after {
      height: calc(1px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    & > li:first-child {
      margin-right: calc(20px / vars.$medium * 100vw);
    }
    & li.selected::after {
      height: calc(1px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    & > li:first-child {
      margin-right: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    }
    & li.selected::after {
      height: calc(1px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}
