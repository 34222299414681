@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.container {
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  gap: 10px;
  cursor: pointer;
  &:hover {
    color: vars.$blue;
  }
  @extend .Head;
  @media screen and (max-width: vars.$big) {
    gap: calc(10px / vars.$big * 100vw);
  }
}

.link-arrow {
  display: block;
  width: 13px;
  height: 7px;
  margin-top: 3px;
  @media screen and (max-width: vars.$big) {
    margin-top: calc(3px / vars.$big * 100vw);
    width: calc(13px / vars.$big * 100vw);
    height: calc(7px / vars.$big * 100vw);
  }
}

.hide {
  display: none;
}

.selected {
  color: vars.$blue;
}
