@use "../../utils/variables" as vars;

.wrapper {
  width: 100vw;
  background: vars.$white;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  z-index: -1;
  opacity: 0;
  box-shadow: 0px 9px 10px rgba(38, 59, 71, 0.15);
}

.no-shadow {
  box-shadow: none;
}

.container {
  width: 100%;
  max-width: vars.$big;
  margin: 0 100px;
  padding-bottom: 39px;
  align-content: center;
  @media screen and (max-width: vars.$big) {
    margin: 0 calc(100px / vars.$big * 100vw);
    padding-bottom: calc(39px / vars.$big * 100vw);
  }
}

.links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 37px;
  margin-top: 14px;
  grid-column: 4 / 8;
  & > a {
    position: relative;
    grid-row: 2 / 3;
    opacity: 0;
    z-index: -10;
  }
  @media screen and (max-width: vars.$big) {
    margin-top: calc(14px / vars.$big * 100vw);
    column-gap: calc(37px / vars.$big * 100vw);
  }
}

.sublinks {
  grid-row: 1 / 2;
  height: 0;
  overflow: hidden;
  & > a:not(:last-child) {
    margin-bottom: 10px;
  }
  &:nth-of-type(1) {
    grid-column: 1 / 6;
  }
  &:nth-of-type(2) {
    grid-column: 2 / 6;
  }
  &:nth-of-type(3) {
    grid-column: 3 / 6;
  }
  &:nth-of-type(4) {
    grid-column: 4 / 6;
  }
  &:nth-of-type(5) {
    grid-column: 5 / 6;
  }
  @media screen and (max-width: vars.$big) {
    & > a:not(:last-child) {
      margin-bottom: calc(10px / vars.$big * 100vw);
    }
  }
}
