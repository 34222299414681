@use "../utils/variables" as vars;
@import "./fonts.scss";

@font-face {
  font-family: Suisse Intl;
  font-weight: 400;
  font-display: swap;
  src: url("../assets/SuisseIntl/SuisseIntl-Regular.woff2") format("woff2"),
    url("../assets/SuisseIntl/SuisseIntl-Regular.woff") format("woff");
}

@font-face {
  font-family: Suisse Intl;
  font-weight: 500;
  font-display: swap;
  src: url("../assets/SuisseIntl/SuisseIntl-Medium.woff2") format("woff2"),
    url("../assets/SuisseIntl/SuisseIntl-Medium.woff") format("woff");
}

:root {
  // fix 100vh on mobile devices
  // https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Suisse Intl", sans-serif;
  font-size: 10px;
  color: vars.$black;
  overflow-x: hidden;
}

html {
  overflow-y: overlay;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.scrollbar:not(.macos)::-webkit-scrollbar {
  background-color: rgba($color: #000000, $alpha: 0);
  width: 4px;
  height: 2px;
  z-index: 999999;
}
.scrollbar__hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar:not(.macos)::-webkit-scrollbar {
  background-color: rgba($color: #000000, $alpha: 0);
  width: 4px;
  height: 2px;
  z-index: 999999;
}

/* scrollbar itself */
.scrollbar:not(.macos)::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: gray;
}

/* set button(top and bottom of the scrollbar) */
.scrollbar:not(.macos)::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
.scrollbar:not(.macos):hover::-webkit-scrollbar-thumb {
  background-color: gray;
  border: 4px solid gray;
}

/* scrollbar when scrollbar is hovered */
.scrollbar:not(.macos)::-webkit-scrollbar-thumb:hover {
  background-color: #383838;
  border: 4px solid #383838;
}

// yandex maps styling
.popover {
  display: grid;
  min-width: 310px;
  position: absolute;
  background: #fff;
  border-radius: 10px;
  font-family: "Suisse Intl", sans-serif;
  box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.2);
  &-title {
    font-weight: 300;
    font-size: 24px;
  }
  &__content {
    padding: 0 30px;
  }
}

.balloonContent {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 30px;
  padding-top: 0px;
  padding-right: 20px;
  width: 100%;
  font-size: 12px;
  & > h3 {
    text-transform: uppercase;
  }
}

.close {
  display: block;
  position: relative;
  justify-self: end;
  width: 12px;
  height: 12px;
  margin: 12px 12px 0 0;
  &::after,
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: vars.$black;
    position: absolute;
    top: 50%;
    left: 0;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
}

.arrow,
.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover .arrow {
  border-width: 11px;
}

.popover .arrow:after {
  border-width: 10px;
  content: "";
}

.popover.top .arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  // border-top-color: #999;
  // border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.popover.top .arrow:after {
  bottom: 1px;
  margin-left: -10px;
  border-top-color: #fff;
  border-bottom-width: 0;
}
