@use "../../utils/variables" as vars;
@import "../../styles/fonts.scss";

.wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
  flex-grow: 1;
  position: relative;
  background: vars.$white;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0%;
    background: vars.$white;
    z-index: 1;
    transition: height 0.2s linear;
  }
  @media screen and (max-width: vars.$small) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding-bottom: calc(12px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.wrapper__dark {
  background: vars.$black;
  color: vars.$white;
}

.wrapper.hover-menu,
.wrapper__dark.hover-menu {
  color: vars.$black;
  &::after {
    height: 120%;
  }
  @media screen and (max-width: vars.$medium) {
    &::after {
      height: 100%;
    }
  }
}

.container {
  width: 100%;
  max-width: vars.$big;
  margin: 32px 100px;
  align-content: center;
  max-height: 66px;
  margin-bottom: 0;
  position: relative;
  z-index: 200;
  @media screen and (max-width: vars.$big) {
    max-height: calc(66px / vars.$big * 100vw);
    margin: calc(32px / vars.$big * 100vw) calc(100px / vars.$big * 100vw);
    margin-bottom: 0;
  }
  @media screen and (max-width: vars.$medium) {
    max-height: calc(52px / vars.$medium * 100vw);
    margin: calc(18px / vars.$medium * 100vw) calc(40px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    max-height: calc(50px / vars.$small * vars.$smallCoef * 100vw);
    margin: calc(12px / vars.$small * vars.$smallCoef * 100vw)
      calc(20px / vars.$small * vars.$smallCoef * 100vw);
    margin-bottom: 0;
  }
}

.logo {
  grid-column: 1 / 3;
  align-self: center;
  @media screen and (max-width: vars.$medium) {
    grid-column: 1 / 2;
  }
  @media screen and (max-width: vars.$small) {
    grid-row: 1 / 2;
    margin-right: 0;
  }
}

.links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 37px;
  grid-column: 4 / 8;
  @media screen and (max-width: vars.$big) {
    gap: calc(37px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    display: none;
  }
}

.language {
  grid-column: 9 / 10;
  margin-left: 40px;
  @media screen and (max-width: vars.$big) {
    margin-left: calc(40px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    margin-left: 0;
    margin-right: calc(100px / vars.$medium * 100vw);
    justify-self: end;
  }
  @media screen and (max-width: vars.$small) {
    grid-column: 1 / 3;
    margin-right: calc(70px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.discuss-button {
  grid-column: 10 / 13;
  justify-self: end;
  @media screen and (max-width: vars.$medium) {
    grid-column: 3 / 5;
    grid-row: 1 / 2;
    margin-right: calc(110px / vars.$medium * 100vw);
    justify-self: end;
  }
  @media screen and (max-width: vars.$small) {
    display: none;
  }
}

.burger {
  display: none !important;
  @media screen and (max-width: vars.$medium) {
    display: flex !important;
    grid-column: 4 / 5;
    grid-row: 1 / 2;
    justify-self: end !important;
    margin-top: calc(15px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    margin-top: calc(7px / vars.$small * vars.$smallCoef * 100vw);
    grid-column: 2 / 3;
  }
}
