@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  opacity: 0;
  z-index: -1000;
  overflow: hidden;
}

.container {
  display: grid;
  grid-template-columns: 1fr;
  position: absolute;
  align-items: start;
  align-content: start;
  overflow: auto;
  top: 0;
  right: 0;
  height: 100%;
  width: 940px;
  background: vars.$blue;
  & > span {
    color: vars.$blue2;
    text-transform: uppercase;
    margin-top: 259px;
    margin-left: 100px;
    margin-bottom: 80px;
    @extend .H3;
  }
  @media screen and (max-width: vars.$big) {
    width: calc(940px / vars.$big * 100vw);
    & > span {
      margin-top: calc(259px / vars.$big * 100vw);
      margin-left: calc(100px / vars.$big * 100vw);
      margin-bottom: calc(80px / vars.$big * 100vw);
    }
  }

  @media screen and (max-width: vars.$medium) {
    width: calc(1024px / vars.$medium * 100vw);
    & > span {
      margin-top: calc(200px / vars.$medium * 100vw);
      margin-left: calc(40px / vars.$medium * 100vw);
      margin-bottom: calc(80px / vars.$medium * 100vw);
    }
  }

  @media screen and (max-width: vars.$small) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    & > span {
      margin-top: calc(100px / vars.$small * vars.$smallCoef * 100vw);
      margin-left: calc(20px / vars.$small * vars.$smallCoef * 100vw);
      margin-bottom: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.close {
  display: block;
  position: absolute;
  color: vars.$white;
  width: 50px;
  height: 50px;
  justify-self: end;
  right: 60px;
  top: 60px;
  cursor: pointer;
  &:hover {
    color: vars.$blue2;
  }
  &::after,
  &::before {
    content: "";
    width: 100%;
    top: 25px;
    left: 0;
    height: 2px;
    background: currentColor;
    position: absolute;
    transform-origin: center;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
  @media screen and (max-width: vars.$big) {
    width: calc(50px / vars.$big * 100vw);
    height: calc(50px / vars.$big * 100vw);
    right: calc(60px / vars.$big * 100vw);
    top: calc(60px / vars.$big * 100vw);
    &::after,
    &::before {
      top: calc(25px / vars.$big * 100vw);
      height: calc(2px / vars.$big * 100vw);
    }
  }

  @media screen and (max-width: vars.$medium) {
    width: calc(50px / vars.$medium * 100vw);
    height: calc(50px / vars.$medium * 100vw);
    right: calc(40px / vars.$medium * 100vw);
    top: calc(40px / vars.$medium * 100vw);
    &::after,
    &::before {
      top: calc(25px / vars.$medium * 100vw);
      height: calc(2px / vars.$medium * 100vw);
    }
  }

  @media screen and (max-width: vars.$small) {
    width: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    height: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    right: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    &::after,
    &::before {
      top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
      height: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.form {
  display: grid;
  height: auto;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 100px;
  align-items: start;
  column-gap: 30px;
  row-gap: 20px;
  @media screen and (max-width: vars.$big) {
    padding: 0 calc(100px / vars.$big * 100vw);
    column-gap: calc(30px / vars.$big * 100vw);
    row-gap: calc(20px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    padding: 0 calc(40px / vars.$medium * 100vw);
    column-gap: calc(30px / vars.$medium * 100vw);
    row-gap: calc(20px / vars.$medium * 100vw);
  }

  @media screen and (max-width: vars.$small) {
    grid-template-columns: 1fr;
  }
}

.comment {
  grid-column: 1 / -1;
}

.btn {
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  justify-self: end;
  @media screen and (max-width: vars.$small) {
    grid-row: auto;
    margin-right: 0;
    margin-top: calc(20px / vars.$small * vars.$smallCoef * 100vw);
    justify-self: start;
  }
}

.checkbox {
  grid-column: 1 / -1;
  grid-row: 5 / 6;
  & > p {
    max-width: 345px;
  }
  @media screen and (max-width: vars.$big) {
    & > p {
      max-width: calc(369px / vars.$big * 100vw);
    }
  }
  @media screen and (max-width: vars.$medium) {
    & > p {
      max-width: calc(300px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    grid-row: auto;
    & > p {
      max-width: calc(230px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.error {
  color: vars.$error_color;
  grid-column: 1 / -1;
  text-transform: uppercase;
  @extend .T1;
  @media screen and (max-width: vars.$small) {
    grid-row: 6 / 7;
    margin-bottom: calc(20px / vars.$small * vars.$smallCoef * 100vw);
  }
}

.outside-click {
  width: 100%;
  @media screen and (max-width: vars.$medium) {
    display: none;
  }
}
