@use "../../utils/variables" as vars;

.container {
  display: inline-block;
  cursor: pointer;

  & > svg {
    width: 131px;
    height: auto;
    @media screen and (max-width: vars.$big) {
      width: calc(131px / vars.$big * 100vw);
    }
    @media screen and (max-width: vars.$medium) {
      width: calc(120px / vars.$medium * 100vw);
    }
    @media screen and (max-width: vars.$small) {
      width: calc(120px * vars.$smallCoef / vars.$small * 100vw);
    }
  }
}
