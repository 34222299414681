@use "../../../utils/variables" as vars;
@import "../../../styles/fonts.scss";

.container {
  padding: 20px 40px;
  display: inline-flex;
  border-radius: 40px;
  box-shadow: inset 0 0 0 1px vars.$black;
  color: vars.$black;
  text-transform: uppercase;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  justify-content: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: color 0.5s ease;

  & > .title {
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    width: 0%;
    aspect-ratio: 1/1;
    background: vars.$blue;
    border-radius: 50%;
    transform-origin: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease;
  }

  &:hover {
    color: vars.$white;
    box-shadow: inset 0 0 0 1px vars.$blue;
  }
  &:hover::after {
    width: 110%;
  }

  @extend .Head;
  @media screen and (max-width: vars.$big) {
    padding: calc(20px / vars.$big * 100vw) calc(40px / vars.$big * 100vw);
  }

  @media screen and (max-width: vars.$medium) {
    padding: calc(15px / vars.$medium * 100vw) calc(30px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    padding: calc(16px * vars.$smallCoef / vars.$small * 100vw)
      calc(30px * vars.$smallCoef / vars.$small * 100vw);
    border-radius: calc(40px * vars.$smallCoef / vars.$small * 100vw);
  }
}

.light {
  box-shadow: inset 0 0 0 1px vars.$white;
  color: vars.$white;
  &::after {
    background: vars.$white;
  }
  &:hover {
    color: vars.$blue;
    box-shadow: inset 0 0 0 1px vars.$white;
  }
}
