.H1 {
  font-size: 90px;
  line-height: 110px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: calc(90px / vars.$big * 100vw);
    line-height: calc(110px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(74px / vars.$medium * 100vw);
    line-height: calc(92px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(32px * vars.$smallCoef / vars.$small * 100vw);
    line-height: calc(46px * vars.$smallCoef / vars.$small * 100vw);
  }
}

.H2 {
  font-size: 54px;
  line-height: 68px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: calc(54px / vars.$big * 100vw);
    line-height: calc(68px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(42px / vars.$medium * 100vw);
    line-height: calc(56px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(28px * vars.$smallCoef / vars.$small * 100vw);
    line-height: calc(44px * vars.$smallCoef / vars.$small * 100vw);
  }
}

.H3 {
  font-size: 28px;
  line-height: 40px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: calc(28px / vars.$big * 100vw);
    line-height: calc(40px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(21px / vars.$medium * 100vw);
    line-height: calc(32px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(17px * vars.$smallCoef / vars.$small * 100vw);
    line-height: calc(30px * vars.$smallCoef / vars.$small * 100vw);
  }
}

.H4 {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: calc(32px / vars.$big * 100vw);
    line-height: calc(42px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(23px / vars.$medium * 100vw);
    line-height: calc(34px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: calc(21px * vars.$smallCoef / vars.$small * 100vw);
    line-height: calc(32px * vars.$smallCoef / vars.$small * 100vw);
  }
}

.T1 {
  font-size: 22px;
  line-height: 32px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: calc(22px / vars.$big * 100vw);
    line-height: calc(32px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(17px / vars.$medium * 100vw);
    line-height: calc(26px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: 17px;
    line-height: 26px;
  }
}

.T2 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: calc(14px / vars.$big * 100vw);
    line-height: calc(24px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(12px / vars.$medium * 100vw);
    line-height: calc(20px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: 12px;
    line-height: 18px;
  }
}

.B1 {
  font-size: 21px;
  line-height: 30px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: calc(21px / vars.$big * 100vw);
    line-height: calc(30px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(16px / vars.$medium * 100vw);
    line-height: calc(28px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: 16px;
    line-height: 28px;
  }
}

.B2 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  @media screen and (max-width: vars.$big) {
    font-size: calc(18px / vars.$big * 100vw);
    line-height: calc(26px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(14px / vars.$medium * 100vw);
    line-height: calc(24px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: 13px;
    line-height: 23px;
  }
}

.Head {
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  @media screen and (max-width: vars.$big) {
    font-size: calc(18px / vars.$big * 100vw);
    line-height: calc(26px / vars.$big * 100vw);
  }
  @media screen and (max-width: vars.$medium) {
    font-size: calc(14px / vars.$medium * 100vw);
    line-height: calc(24px / vars.$medium * 100vw);
  }
  @media screen and (max-width: vars.$small) {
    font-size: 14px;
    line-height: 24px;
  }
}
