@use "../../../utils/variables" as vars;

.container {
  display: flex;
  height: 24px;
  width: 50px;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: vars.$white;
  & > span {
    height: 3px;
    width: 100%;
    background: currentColor;
    transition: all 0.5s ease;
  }
  &::after,
  &::before {
    transition: all 0.5s ease;
    content: "";
    margin: 0;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: currentColor;
  }
  &::after {
    margin-top: -50%;
  }
  &::before {
    margin-top: 50%;
  }
  @media screen and (max-width: vars.$medium) {
    height: calc(24px / vars.$medium * 100vw);
    width: calc(50px / vars.$medium * 100vw);
    & > span {
      height: calc(3px / vars.$medium * 100vw);
    }
    &::after,
    &::before {
      height: calc(3px / vars.$medium * 100vw);
    }
  }
  @media screen and (max-width: vars.$small) {
    height: calc(19px / vars.$small * vars.$smallCoef * 100vw);
    width: calc(40px / vars.$small * vars.$smallCoef * 100vw);
    & > span {
      height: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    }
    &::after,
    &::before {
      height: calc(2px / vars.$small * vars.$smallCoef * 100vw);
    }
  }
}

.opened {
  & > span {
    width: 0;
  }
  background-color: rgba($color: #000000, $alpha: 0);
  &::after {
    margin-top: 0px;
    transform: rotate(-45deg);
    transform-origin: center;
  }
  &::before {
    transform-origin: center;
    margin-top: 0px;
    transform: rotate(45deg);
  }
}

.dark {
  color: vars.$black;
}
